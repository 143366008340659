<template>
  <div class="varietySearch">
    <nf-breadcrumb :breadList="breadList"></nf-breadcrumb>
    <div class="bgWrap">
      <div class="titleWrap">品种权检索</div>
      <div class="searchWrap">
        <el-input placeholder="请输入关键字" v-model="queryName">
          <el-button @click="toDetail" slot="append" icon="el-icon-search"></el-button>
        </el-input>
      </div>
      <div class="varietyListWrap">
        <div
          class="varietyItem"
          @click="$router.push({ path: '/varietyDetail', query: { id: item.id } })"
          v-for="item in list"
          :key="item.id"
        >
          <div class="name">{{ item.breedName }}</div>
          <el-row>
            <el-col :span="12">申请号：{{ item.appCode }}</el-col>
            <el-col :span="12">公告日：{{ item.pubDate }}</el-col>
          </el-row>
          <el-row>
            <el-col :span="12">品种权人：{{ item.owner }}</el-col>
            <el-col :span="12">品种权号：{{ item.ownerNo }}</el-col>
          </el-row>
        </div>
      </div>
      <div class="moreWrap">
        <span @click="$router.push('/varietyList')">查看更多...</span>
      </div>
    </div>
  </div>
</template>

<script>
import nfBreadcrumb from '@/component/breadcrumb/breadcrumb';

export default {
  name: 'varietySearch',
  data() {
    return {
      breadList: [{ name: '热点功能' }, { name: '品种权检索' }], // 导航
      queryName: '', // 关键字
      params: {
        pageNum: 1,
        pageSize: 4
      },
      list: [] // 列表
    };
  },
  methods: {
    // 获取推荐列表
    async getList() {
      const api = this.$fetchApi.varietyList.api;
      const data = await this.$fetchData.fetchPost(this.params, api, 'json');
      if (data.code === '200') {
        this.list = data.result;
      }
    },
    // 跳转详情
    toDetail() {
      this.$router.push({
        path: '/varietyList',
        query: { queryName: this.queryName }
      });
    }
  },
  created() {
    this.getList();
  },
  components: { nfBreadcrumb }
};
</script>

<style lang="less" scoped>
.bgWrap {
  height: 837px;
  background-image: url(~@/assets/images/pzqjs_bk@3x.png);
  background-repeat: no-repeat;
  background-position: center 0;
  background-size: cover;
  overflow: hidden;
}
.titleWrap {
  position: absolute;
  font-size: 40px;
  color: #fff;
  left: 50%;
  font-weight: 700;
  letter-spacing: 2px;
  top: 370px;
  transform: translate(-50%);
}
.searchWrap {
  margin: 275px auto;
  margin-bottom: 125px;
  width: 1000px;
  height: 60px;
  background: rgba(255, 255, 255, 0.5);
  .el-input {
    width: 990px;
    // height: 50px;
    box-sizing: border-box;
    margin: 5px 5px;
  }
  /deep/ .el-input__inner {
    height: 50px;
  }
  /deep/ .el-input-group__append {
    background: #448afc;
    color: #fff;
    font-size: 20px;
    width: 73px;
    box-sizing: border-box;
    text-align: center;
  }
}
.varietyListWrap {
  width: 1000px;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  .varietyItem {
    position: relative;
    background: rgba(255, 255, 255, 0.7);
    width: 480px;
    height: 120px;
    box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.3);
    margin-bottom: 40px;
    box-sizing: border-box;
    padding: 10px;
    padding-left: 30px;
    .el-row {
      padding-bottom: 5px;
      .el-col {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        color: #444;
      }
    }
    &::before {
      content: '';
      display: block;
      position: absolute;
      width: 6px;
      height: 12px;
      border-radius: 3px;
      background: #265ae1;
      left: 15px;
      top: 16px;
    }
    .name {
      font-size: 18px;
      font-weight: 700;
      padding-bottom: 15px;
    }
  }
}
.moreWrap {
  text-align: center;
  color: #4396e4;
}
</style>
